import React from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  Container,
  Divider,
} from "@chakra-ui/react";

const Disclaimer = () => {
  return (
    <Container maxW="container.xl" py={10}>
      <VStack spacing={5} align="start">
        <Heading as="h1" size="xl">
          Disclaimer
        </Heading>

        <Text>
          Thank you for visiting our website. We created this platform to
          provide insights and resources in addition to information about
          politics, sports, entertainment, and news.
        </Text>

        <Divider />

        <Text>
          We have incorporated the following disclaimers to guarantee openness
          and clarity:
        </Text>

        <Text>
          Only for Informational Purposes This website only serves to provide
          information. Our website's content should not be interpreted as
          professional guidance.
        </Text>

        <Box pl={4} borderLeft="4px" borderColor="#FE3700">
          <Heading as="h3" size="md" mb={2}>
            There is no expert guidance.
          </Heading>
          <Text>
            The information on this website should not be viewed as a substitute
            for professional advice. We strongly encourage you to seek the
            advice of a qualified professional advisor if you have specific
            questions or require assistance with financial, legal, or other
            professional matters. Advice and services that are tailored to your
            needs should always come first.
          </Text>
        </Box>

        <Box pl={4} borderLeft="4px" borderColor="#FE3700">
          <Heading as="h3" size="md" mb={2}>
            There needs to be a professional connection.
          </Heading>
          <Text>
            By accessing this website, you and our team do not enter into a
            professional relationship or agreement. To become a client, a formal
            written agreement is required.
          </Text>
        </Box>

        <Box pl={4} borderLeft="4px" borderColor="#FE3700">
          <Heading as="h3" size="md" mb={2}>
            No Professional Relationship
          </Heading>
          <Text>
            Accessing this website does not establish a professional
            relationship or agreement between you and our team. To become a
            client, a formal written agreement is required.
          </Text>
        </Box>

        <Box pl={4} borderLeft="4px" borderColor="#FE3700">
          <Heading as="h3" size="md" mb={2}>
            Links from outside.
          </Heading>
          <Text>
            On our website, there may be links to other websites. These links
            are provided solely for informational purposes and as a convenience.
            We need to have command over the substance or precision of these
            outside sites and can't take care of their substance.
          </Text>
        </Box>

        <Box pl={4} borderLeft="4px" borderColor="#FE3700">
          <Heading as="h3" size="md" mb={2}>
            Testimonials and case studies
          </Heading>
          <Text>
            Our website's case studies and client testimonials are only for
            illustrational purposes. The outcomes shown in these examples may
            not be identical for all customers. Depending on the particular
            circumstances, individual outcomes may differ.
          </Text>
        </Box>

        <Text>
          We appreciate your understanding and interest in our website. Please
          don't hesitate to get in touch with us if you have any inquiries or
          require additional clarification.
        </Text>
      </VStack>
    </Container>
  );
};

export default Disclaimer;
