import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Home from "./pages/home";
import Entertainment from "./pages/entertainment";
import ReadMore from "./pages/entertainment/readMore";
import entertainment from "./entertainment.json";
import { Box } from "@chakra-ui/react";
import HealthyEating from "./pages/healthy-eating";
import MedicalProblems from "./pages/medical-problems";
import Education from "./pages/education";
import About from "./pages/about";
import Contact from "./pages/contact";
import Header from "./Compenent/header";
import Sport from "./pages/sport";
import Footer from "./Compenent/footer";
import SportBlogDetails from "./pages/SportBlogDetails";
import ViewData from "./pages/entertainment/viewData";
import { useEffect } from "react";
import Medals from "./pages/medals";
import Disclaimer from "./pages/disclaimer";

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [navigate, location.search]);

  return (
    <div>
      {/* <Box display={"flex"} justifyContent={"center"} my={5}>
        <Box width={"300px"} height={"300px"} bgColor={"#f3f3f3"}></Box>
      </Box> */}
      <Header />
      <Box
        sx={{
          // pt: "80px",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Box display={"flex"} justifyContent={"center"} mt={5}>
          <Box width={"728px"} height={"90px"} bgColor={"#f3f3f3"}></Box>
        </Box>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/entertainment"
            element={<Entertainment entertainment={entertainment} />}
          />
          <Route
            path="/healthy-eating"
            element={<HealthyEating entertainment={entertainment} />}
          />
          <Route
            path="/medical-problems"
            element={<MedicalProblems entertainment={entertainment} />}
          />
          <Route
            path="/education"
            element={<Education entertainment={entertainment} />}
          />
          <Route path="/category" element={<Sport />} />
          <Route path="/medals" element={<Medals />} />
          {/* <Route
            path="/"
            element={<Navigate to={'/category?name=sports'} />}
          /> */}
          <Route
            path="/about-us"
            element={<About entertainment={entertainment} />}
          />
          <Route
            path="/contact-us"
            element={<Contact entertainment={entertainment} />}
          />
          <Route
            path="/:title"
            element={<ReadMore entertainment={entertainment} />}
          />
          <Route path="/blog-details" element={<SportBlogDetails />} />
          <Route
            path="/:title/:name"
            element={<ViewData entertainment={entertainment} />}
          />
          <Route path="/disclaimer" element={<Disclaimer />} />
        </Routes>
      </Box>
      <Box display={"flex"} justifyContent={"center"} mt={5}>
        <Box width={"728px"} height={"90px"} bgColor={"#f3f3f3"}></Box>
      </Box>
      <Footer />
      <Box display={"flex"} justifyContent={"center"} my={5}>
        <Box width={"728px"} height={"90px"} bgColor={"#f3f3f3"}></Box>
      </Box>
    </div>
  );
}

export default App;
