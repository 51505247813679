import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  Image,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FiAlignCenter } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const location = useLocation();
  const [category, setCategory] = useState([]);

  const getCategory = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}category`
      );
      setCategory(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <Box
      backgroundColor={"#000"}
      color={"white"}
      width={"100%"}
      boxShadow={"lg"}
      position="sticky"
      top="0"
      zIndex="1000"
    >
      <Container maxW={{ base: "container.xl", md: "container.2xl" }}>
        <Box width={"100%"}>
          <Flex
            h={"80px"}
            alignItems={"center"}
            justifyContent={{
              lg: "space-between",
              base: "space-between",
            }}
            gap={{ base: 10, lg: 10 }}
          >
            <Box
              cursor={"pointer"}
            >
              <a href="/">
                <Image
                  src={
                    "https://actressfashion.s3.eu-north-1.amazonaws.com/logo+fevicon02-04.png"
                  }
                  w={"100%"}
                  alt="Mediamingle.live"
                />
              </a>
            </Box>
            <Box>
              <Box>
                <Box
                  alignItems={"center"}
                  as={"nav"}
                  gap={4}
                  spacing={4}
                  display={{ base: "none", lg: "flex" }}
                >
                {category.length > 0 &&  <Box
                    display={"flex"}
                    alignItems={"center"}
                    cursor={"pointer"}
                    // onClick={() => navigate(`/`)}
                  >
                    <a href="/">
                      <Text
                        padding={"8px 10px"}
                        pb={2}
                        w={"100%"}
                        borderBottom={
                          location.pathname.concat(
                            location.search.split("%20").join(" ")
                          ) === `/`
                            ? "2px solid #FE3700"
                            : ""
                        }
                        color={
                          location.pathname.concat(
                            location.search.split("%20").join(" ")
                          ) === `/`
                            ? "#fff"
                            : ""
                        }
                        sx={{
                          fontSize: { base: "16px" },
                          fontWeight: "500",
                          _hover: {
                            borderBottom: "2px solid #FE3700",
                            color: "#fff",
                          },
                        }}
                      >
                        Home
                      </Text>
                    </a>
                  </Box>}
                  {category.length > 0 &&
                    category?.map((item, index) => (
                      <Box
                        key={index}
                        display={"flex"}
                        alignItems={"center"}
                        cursor={"pointer"}
                        onClick={() =>
                          navigate(`/category?name=${item.name.toLowerCase()}`)
                        }
                      >
                        <a href={`/category?name=${item.name.toLowerCase()}`}>
                          <Text
                            padding={"8px 10px"}
                            pb={2}
                            w={"100%"}
                            borderBottom={
                              location.pathname.concat(
                                location.search.split("%20").join(" ")
                              ) === `/category?name=${item.name.toLowerCase()}`
                                ? "2px solid #FE3700"
                                : ""
                            }
                            color={
                              location.pathname.concat(
                                location.search.split("%20").join(" ")
                              ) === `/category?name=${item.name.toLowerCase()}`
                                ? "#fff"
                                : ""
                            }
                            sx={{
                              fontSize: { base: "16px" },
                              fontWeight: "500",
                              _hover: {
                                borderBottom: "2px solid #FE3700",
                                color: "#fff",
                              },
                            }}
                          >
                            {item.name}
                          </Text>
                        </a>
                      </Box>
                    ))}
                </Box>

                <IconButton
                  size={"md"}
                  icon={
                    isOpen ? (
                      <CloseIcon color="#fff" />
                    ) : (
                      <FiAlignCenter size={35} color="#fff" />
                    )
                  }
                  aria-label={"Open Menu"}
                  bgColor={"transparent"}
                  sx={{
                    ":hover": {
                      bgColor: "transparent",
                    },
                  }}
                  display={{ md: "none" }}
                  onClick={isOpen ? onClose : onOpen}
                />
              </Box>
            </Box>
          </Flex>
        </Box>
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          width={"80"}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerBody mt={24}>
             {category.length > 0 && <Box
                display={"flex"}
                alignItems={"center"}
                py={4}
                cursor={"pointer"}
                justifyContent={"space-between"}
                borderBottom={
                  location.pathname.concat(
                    location.search.split("%20").join(" ")
                  ) === `/`
                    ? "2px solid #FE3700"
                    : ""
                }
                color={
                  location.pathname.concat(
                    location.search.split("%20").join(" ")
                  ) === `/`
                    ? "#FE3700"
                    : ""
                }
                gap={2}
                onClick={() => {
                  // navigate(`/`);
                  onClose();
                }}
              >
                <Text
                  sx={{
                    fontSize: { base: "17px", lg: "20px" },
                    fontWeight: "500",
                    padding: "0 10px",
                  }}
                >
                  <a href="/">Home</a>
                </Text>
              </Box>}
              {category?.map((item, index) => (
                <Box
                  key={index}
                  display={"flex"}
                  alignItems={"center"}
                  py={4}
                  cursor={"pointer"}
                  justifyContent={"space-between"}
                  borderBottom={
                    location.pathname.concat(
                      location.search.split("%20").join(" ")
                    ) === `/category?name=${item.name.toLowerCase()}`
                      ? "2px solid #FE3700"
                      : ""
                  }
                  color={
                    location.pathname.concat(
                      location.search.split("%20").join(" ")
                    ) === `/category?name=${item.name.toLowerCase()}`
                      ? "#FE3700"
                      : ""
                  }
                  gap={2}
                  onClick={() => {
                    onClose();
                  }}
                >
                  <Text
                    sx={{
                      fontSize: { base: "17px", lg: "20px" },
                      fontWeight: "500",
                      padding: "0 10px",
                    }}
                  >
                    <a href={`/category?name=${item.name.toLowerCase()}`}>
                      {item.name}
                    </a>
                  </Text>
                </Box>
              ))}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Container>
    </Box>
  );
};

export default Header;
